<template>
  <section class="container mb-14 sm:mb-20">
    <div class="container__inner sub-page pt-6 md:pt-12">
      <div class="mb-14 sm:mb-20">
        <h1 class="text-royalblue-900 mb-10">Frequently Asked Questions</h1>
        <div class="flex flex-wrap -m-4">
          <div class="w-full lg:w-1/2 p-4">
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              How long will each treatment last?
            </h2>
            <p>
              This is usually around an hour, an assessment may take longer at
              around 1.5 hours. Treatment times can be agreed if these need to
              be different.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              How much will it cost?
            </h2>
            <p>
              Please view our
              <router-link :to="{ name: 'Prices' }">Prices</router-link> page
              for charges.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              How should I pay you?
            </h2>
            <p>
              The method of payment which works best for you will be agreed upon
              before we visit, or when we complete our assessment; we can take
              cash, cheque or payment transfer via BACS.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              What should I wear?
            </h2>
            <p>Comfortable loose fitting clothing is best.</p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              Am I committed to a certain number of treatments?
            </h2>
            <p>
              No. We shall agree a plan with you, this may be each time we
              visit. It can take a couple of visits for us to complete our
              assessment and make sure we are giving you the appropriate advice.
            </p>
          </div>
          <div class="w-full lg:w-1/2 p-4">
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              How often will you visit?
            </h2>
            <p>
              No. We shall agree a plan with you, this may be each time we
              visit. It can take a couple of visits for us to complete our
              assessment and make sure we are giving you the appropriate advice.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              Do you do 'Special Offers'?
            </h2>
            <p>No.</p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              Do I need a referral?
            </h2>
            <p>
              No. Give us a call or email us to discuss what you feel you are
              needing and we can go ahead without a GP or Consultant referral.
              If you intend to pay via insurance or case manager then this needs
              to be clarified and authorised before we commence.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              Can a friend or relative be present?
            </h2>
            <p>
              Yes, this is often a very helpful addition to our sessions as we
              can share information, clarify and assist with any problems which
              may be a challenge at the moment. However, as our client you need
              to consent to having an individual present.
            </p>
            <h2 class="h5 text-royalblue-400 mb-4 leading-relaxed">
              Can I cancel my appointment?
            </h2>
            <p>
              We understand that sometimes the best laid plans can be
              interrupted, however we would appreciate at least 24 hours notice
              if you feel you need to cancel.
            </p>
            <p>
              If you are unwell then please let us know as soon as possible. We
              may have to administer a charge if a cancellation is made with
              less than 24hours notice.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Faqs",
};
</script>
